import { GameId, LanguageCode } from '@/core/system';

export interface CreateTicketCommand {
  game: GameId;
  language: LanguageCode;
  message: string;
  playerName: string;
  email: string;
  reason: string;
  issue: string;
  files: File[];
  tosAgreement: boolean;
  playerId: string;

  loginId?: string;
  advId?: string;
  gameVersion?: string;
  platformType?: string;
  deviceModel?: string;
  gameLocale?: string;
  deviceLocale?: string;
  utcOffset?: number;
}

export enum CreatingTicketResponseStatus {
  Created = 'created',
  InProcess = 'inProcess',
  Duplicate = 'duplicate',
}

export interface CreateTicketResponseViewModel {
  status: CreatingTicketResponseStatus;
  ticketNumber: string;
}
