










































import { Component, Inject, Vue } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import ControlError from '@/themes/v1/components/ControlError.vue';
import { ValidationProvider } from 'vee-validate';
import BoxPage from '@themes/v1/components/BoxPage.vue';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';
import config from '@/env';
import { getModule } from 'vuex-module-decorators';
import { PlayerStore } from '../../../stores/player.store';
import {
  CreateTicketCommand,
  CreatingTicketResponseStatus,
} from '@/modules/sts/creator/create-ticket.model';
import { SsrCtx, WithAsyncData, WithMeta } from '@/core/vue.types';
import { pushWithLangAndPlatform } from '@/themes/v1/fns/router';
import { AppStore } from '@/themes/v1/stores/app.store';
import { ChatStore } from '@/themes/v1/stores/chat.store';
import { MetaInfo } from 'vue-meta';
import TestLocator from '@/core/test-utils/test-locator';
import Spinner from '@/themes/v1/components/Spinner.vue';

const availableLanguages = ['en', 'ru', 'es', 'tr'];

@Component({
  components: {
    BoxPage,
    ControlError,
    BoxContent,
    ValidationProvider,
    Spinner,
  },
  directives: {
    TestLocator,
  },
})
export default class RemoveAccount
  extends Vue
  implements WithAsyncData, WithMeta {
  @Inject()
  ticketCreatorService!: TicketCreatorService;

  async asyncData(ctx: SsrCtx) {}

  playerStore = getModule(PlayerStore, this.$store);
  appStore = getModule(AppStore, this.$store);

  email = '';

  processing = false;

  get platform() {
    return this.playerStore.platformType || 'android';
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.tickets.removeAccount.title') as string,
    };
  }

  async submit() {
    let language =
      this.$i18n.locale ||
      this.playerStore.gameLocale ||
      this.playerStore.deviceLocale ||
      'en';
    if (!availableLanguages.includes(language.toLocaleLowerCase())) {
      language = 'en';
    }

    const command: CreateTicketCommand = {
      message: this.$t('pages.tickets.removeAccount.requestText') as string,
      language,
      email: this.email,
      playerName: this.playerStore.playerName || '',
      files: [],
      game: config.ticketsGame,
      reason: 'account_delete',
      issue: '',
      tosAgreement: true,
      playerId: this.playerStore.playerId,
      platformType: this.playerStore.platformType,
      loginId: this.playerStore.loginId,
      advId: this.playerStore.advId,
      gameVersion: this.playerStore.gameVersion,
      deviceModel: this.playerStore.deviceModel,
      gameLocale: this.playerStore.gameLocale,
      deviceLocale: this.playerStore.deviceLocale,
      utcOffset: this.playerStore.utcOffset,
    };
    this.processing = true;
    try {
      const result = await this.ticketCreatorService.create(command);
      if (this.playerStore.isAuth) {
        if (result.status === CreatingTicketResponseStatus.Created) {
          await pushWithLangAndPlatform(
            this.$router,
            `tickets/${result.ticketNumber}`
          );
        } else if (result.status === CreatingTicketResponseStatus.Duplicate) {
          const chatStore = getModule(ChatStore, this.$store);
          chatStore.setNotification({
            title: this.$t('phrases.warning') as string,
            text: this.$t('pages.tickets.duplicate-error') as string,
          });
          await pushWithLangAndPlatform(this.$router, `tickets`);
        } else if (result.status === CreatingTicketResponseStatus.InProcess) {
          await pushWithLangAndPlatform(this.$router, `tickets/waiting`);
        } else {
          await pushWithLangAndPlatform(this.$router, 'tickets/failed');
        }
      } else {
        if (result.status === CreatingTicketResponseStatus.Duplicate) {
          await pushWithLangAndPlatform(this.$router, 'tickets/failed');
          return;
        }
        await pushWithLangAndPlatform(this.$router, 'tickets/success');
      }
    } catch (e) {
      await pushWithLangAndPlatform(this.$router, 'tickets/failed');
    } finally {
      this.processing = false;
    }
  }
}
